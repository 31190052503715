import React, { createContext, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../environment";
import { useHistory } from "react-router";
import localForage from "localforage";
import moment from "moment";

export const TournamentContext = createContext();
export function TournamentProvider({ children }) {
  const [groupState, setGroupState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groups, setTournamentGroups] = useState([]);
  const [knockOut, setKnockOutSixteen] = useState([]);
  const [userAddedPredictions, setUserAddedPredictions] = useState(null);
  const [postOrPut, setPostOrPut] = useState("post");
  const [userEditPressed, setUserEditPressed] = useState(true);
  const [tournamentStartTime, setTournamentStartTime] = useState(null);
  const [tournamentEndOfGroupStage, setTournamentEndOfGroupStage] =
    useState(null);

  const [stageSize, setStageSize] = useState("120rem");
  const [stageIndex, setStageIndex] = useState(0);
  const history = useHistory();

  const isAfterEndOfGroupStage = moment().isAfter(tournamentEndOfGroupStage);

  //check if the user made a submission in the past
  useEffect(() => {
    if (userAddedPredictions?.isLastSubmission === true) {
      !userAddedPredictions?.isFromLocalStorage && setPostOrPut("put");
      setUserEditPressed(false);
    }
  }, [userAddedPredictions]);

  const handleEdit = () => {
    setUserEditPressed(!userEditPressed);
  };

  // Groups
  const [group_a, setGroupA] = useState([]);
  const [group_b, setGroupB] = useState([]);
  const [group_c, setGroupC] = useState([]);
  const [group_d, setGroupD] = useState([]);
  const [group_e, setGroupE] = useState([]);
  const [group_f, setGroupF] = useState([]);

  //group c ranking id's
  const firstPlace_group_c_forPost = group_c?.find(
    (item) => item?.rank === 1
  )?.teamId;
  const secondPlace_group_c_forPost = group_c?.find(
    (item) => item?.rank === 2
  )?.teamId;
  const thirdPlace_group_c_forPost = group_c?.find(
    (item) => item?.rank === 3
  )?.teamId;

  //group d ranking id's
  const firstPlace_group_d_forPost = group_d?.find(
    (item) => item?.rank === 1
  )?.teamId;
  const secondPlace_group_d_forPost = group_d?.find(
    (item) => item?.rank === 2
  )?.teamId;
  const thirdPlace_group_d_forPost = group_d?.find(
    (item) => item?.rank === 3
  )?.teamId;

  //group e ranking id's
  const firstPlace_group_e_forPost = group_e?.find(
    (item) => item?.rank === 1
  )?.teamId;
  const secondPlace_group_e_forPost = group_e?.find(
    (item) => item?.rank === 2
  )?.teamId;
  const thirdPlace_group_e_forPost = group_e?.find(
    (item) => item?.rank === 3
  )?.teamId;

  //group f ranking id's
  const firstPlace_group_f_forPost = group_f?.find(
    (item) => item?.rank === 1
  )?.teamId;
  const secondPlace_group_f_forPost = group_f?.find(
    (item) => item?.rank === 2
  )?.teamId;
  const thirdPlace_group_f_forPost = group_f?.find(
    (item) => item?.rank === 3
  )?.teamId;

  //group a ranking id's
  const firstPlace_group_a_forPost = group_a?.find(
    (item) => item?.rank === 1
  )?.teamId;
  const secondPlace_group_a_forPost = group_a?.find(
    (item) => item?.rank === 2
  )?.teamId;
  const thirdPlace_group_a_forPost = group_a?.find(
    (item) => item?.rank === 3
  )?.teamId;

  //group b ranking id's
  const firstPlace_group_b_forPost = group_b?.find(
    (item) => item?.rank === 1
  )?.teamId;
  const secondPlace_group_b_forPost = group_b?.find(
    (item) => item?.rank === 2
  )?.teamId;
  const thirdPlace_group_b_forPost = group_b?.find(
    (item) => item?.rank === 3
  )?.teamId;

  // Selected Select-Option
  const [select03, setSelected03] = useState();
  const [select04, setSelected04] = useState();
  const [select06, setSelected06] = useState();
  const [select08, setSelected08] = useState();

  // Selected in Sixteen
  const [selectedSixteen1, setSelectedSixteen1] = useState();
  const [selectedSixteen2, setSelectedSixteen2] = useState();
  const [selectedSixteen3, setSelectedSixteen3] = useState();
  const [selectedSixteen4, setSelectedSixteen4] = useState();
  const [selectedSixteen5, setSelectedSixteen5] = useState();
  const [selectedSixteen6, setSelectedSixteen6] = useState();
  const [selectedSixteen7, setSelectedSixteen7] = useState();
  const [selectedSixteen8, setSelectedSixteen8] = useState();

  const selectedSixteenArr = [
    selectedSixteen1,
    selectedSixteen2,
    selectedSixteen3,
    selectedSixteen4,
    selectedSixteen5,
    selectedSixteen6,
    selectedSixteen7,
    selectedSixteen8,
  ];

  // Selected in Quarter
  const [selectedQuarter1, setSelectedQuarter1] = useState();
  const [selectedQuarter2, setSelectedQuarter2] = useState();
  const [selectedQuarter3, setSelectedQuarter3] = useState();
  const [selectedQuarter4, setSelectedQuarter4] = useState();

  const selectedQuarterArr = [
    selectedQuarter1,
    selectedQuarter2,
    selectedQuarter3,
    selectedQuarter4,
  ];

  // Selected in Semi
  const [selectedSemi1, setSelectedSemi1] = useState();
  const [selectedSemi2, setSelectedSemi2] = useState();

  const selectedSemiArr = [selectedSemi1, selectedSemi2];

  // Selected in Final
  const [selectedFinal, setSelectedFinal] = useState();

  const all_knock_out_matches_results = [
    ...selectedSixteenArr,
    ...selectedQuarterArr,
    ...selectedSemiArr,
    selectedFinal,
  ];

  //arrange json for post
  const tournamentPrediction = {
    tournamentId: 271,
    groupPredictions: {
      group_c: {
        1: firstPlace_group_c_forPost,
        2: secondPlace_group_c_forPost,
        3: thirdPlace_group_c_forPost,
      },
      group_d: {
        1: firstPlace_group_d_forPost,
        2: secondPlace_group_d_forPost,
        3: thirdPlace_group_d_forPost,
      },
      group_e: {
        1: firstPlace_group_e_forPost,
        2: secondPlace_group_e_forPost,
        3: thirdPlace_group_e_forPost,
      },
      group_f: {
        1: firstPlace_group_f_forPost,
        2: secondPlace_group_f_forPost,
        3: thirdPlace_group_f_forPost,
      },
      group_a: {
        1: firstPlace_group_a_forPost,
        2: secondPlace_group_a_forPost,
        3: thirdPlace_group_a_forPost,
      },
      group_b: {
        1: firstPlace_group_b_forPost,
        2: secondPlace_group_b_forPost,
        3: thirdPlace_group_b_forPost,
      },
    },
    matchPredictions: [
      {
        matchId: selectedSixteen1?.matchId,
        result: selectedSixteen1?.result,
      },
      {
        matchId: selectedSixteen2?.matchId,
        result: selectedSixteen2?.result,
      },
      {
        matchId: selectedSixteen3?.matchId,
        result: selectedSixteen3?.result,
        awayTeamFromGroup: select03?.fromGroup,
      },
      {
        matchId: selectedSixteen4?.matchId,
        result: selectedSixteen4?.result,
        awayTeamFromGroup: select04?.fromGroup,
      },
      {
        matchId: selectedSixteen5?.matchId,
        result: selectedSixteen5?.result,
      },
      {
        matchId: selectedSixteen6?.matchId,
        result: selectedSixteen6?.result,
        awayTeamFromGroup: select06?.fromGroup,
      },
      {
        matchId: selectedSixteen7?.matchId,
        result: selectedSixteen7?.result,
      },
      {
        matchId: selectedSixteen8?.matchId,
        result: selectedSixteen8?.result,
        awayTeamFromGroup: select08?.fromGroup,
      },
      {
        matchId: selectedQuarter1?.matchId,
        result: selectedQuarter1?.result,
      },
      {
        matchId: selectedQuarter2?.matchId,
        result: selectedQuarter2?.result,
      },
      {
        matchId: selectedQuarter3?.matchId,
        result: selectedQuarter3?.result,
      },
      {
        matchId: selectedQuarter4?.matchId,
        result: selectedQuarter4?.result,
      },
      {
        matchId: selectedSemi1?.matchId,
        result: selectedSemi1?.result,
      },
      {
        matchId: selectedSemi2?.matchId,
        result: selectedSemi2?.result,
      },
      {
        matchId: selectedFinal?.matchId,
        result: selectedFinal?.result,
      },
    ],
    eligibility: sessionStorage.getItem("eligibility"),
  };

  const handleSubmit = async () => {
    const savedToken = sessionStorage.getItem("token");
    const token =
      savedToken?.length && +savedToken !== 0 && savedToken !== "null"
        ? savedToken
        : null;

    if (!token) {
      localStorage.setItem("isSavedSession", true);

      await localForage.setItem("groupState", groupState);
      await localForage.setItem("groups", groups);
      await localForage.setItem("knockOut", knockOut);
      await localForage.setItem("group_a", group_a);
      await localForage.setItem("group_b", group_b);
      await localForage.setItem("group_c", group_c);
      await localForage.setItem("group_d", group_d);
      await localForage.setItem("group_e", group_e);
      await localForage.setItem("group_f", group_f);
      await localForage.setItem("select03", select03);
      await localForage.setItem("select04", select04);
      await localForage.setItem("select06", select06);
      await localForage.setItem("select08", select08);
      await localForage.setItem("selectedSixteen1", selectedSixteen1);
      await localForage.setItem("selectedSixteen2", selectedSixteen2);
      await localForage.setItem("selectedSixteen3", selectedSixteen3);
      await localForage.setItem("selectedSixteen4", selectedSixteen4);
      await localForage.setItem("selectedSixteen5", selectedSixteen5);
      await localForage.setItem("selectedSixteen6", selectedSixteen6);
      await localForage.setItem("selectedSixteen7", selectedSixteen7);
      await localForage.setItem("selectedSixteen8", selectedSixteen8);
      await localForage.setItem("selectedQuarter1", selectedQuarter1);
      await localForage.setItem("selectedQuarter2", selectedQuarter2);
      await localForage.setItem("selectedQuarter3", selectedQuarter3);
      await localForage.setItem("selectedQuarter4", selectedQuarter4);
      await localForage.setItem("selectedSemi1", selectedSemi1);
      await localForage.setItem("selectedSemi2", selectedSemi2);
      await localForage.setItem("selectedFinal", selectedFinal);

      window.parent.location.href =
        "https://m.apuestas.codere.es/#/HomePage?openlogin=true&afterloginurl=CodereMillionPage";
    } else {
      if (postOrPut === "put") {
        axios
          .put(
            `${BASE_URL}/api/edit-tournament-predictions`,
            tournamentPrediction,

            {
              headers: {
                "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
                "jwt-token": token ? token : null,
              },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              setPostOrPut("put");

              console.log("submission was edited successfully!");
              history.push("/submission-success");
              return;
            }
          })
          .catch(function (error) {
            console.log(error);
            return;
          });
      } else {
        axios
          .post(
            `${BASE_URL}/api/add-tournament-predictions`,
            tournamentPrediction,

            {
              headers: {
                "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
                "jwt-token": token ? token : null,
              },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              setPostOrPut("put");
              console.log("submission sent!");
              history.push("/submission-success");
              return;
            }
          })
          .catch(function (error) {
            console.log(error);
            return;
          });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const savedToken = sessionStorage.getItem("token");

      const token =
        savedToken?.length && +savedToken !== 0 && savedToken !== "null"
          ? savedToken
          : null;
      await axios
        .get(`${BASE_URL}/api/get-tournament?tournamentId=271`, {
          headers: {
            "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
            "jwt-token": token ? token : null,
          },
        })
        .then((res) => {
          // preload the images
          const didTournamentStat = moment(
            res.data.tournament.startTime
          ).isBefore(moment());

          if (!token && didTournamentStat) {
            history.push("/unauthorized");
          }

          res?.data?.tournament?.TournamentGroups?.forEach((g) => {
            g?.teams?.forEach((t) => {
              const img = new Image();
              img.src = t?.flag_final_img;
            });
          });
          setTournamentGroups(res?.data?.tournament?.TournamentGroups);
          setKnockOutSixteen(res?.data?.tournament?.rounds);
          setTournamentStartTime(res?.data?.tournament?.startTime);
          setTournamentEndOfGroupStage(
            res?.data?.tournament?.groupStageEndTime
          );
        });
      if (token) {
        let isSubmission;
        await axios
          .get(`${BASE_URL}/api/get-tournament-submission?tournamentId=271`, {
            headers: {
              "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
              "jwt-token": token ? token : null,
            },
          })
          .then((res) => {
            console.log(res);

            res?.data?.tournament?.TournamentGroups?.forEach((g) => {
              g?.teams?.forEach((t) => {
                const img = new Image();
                img.src = t?.flag_final_img;
              });
            });
            isSubmission = !!res?.data?.submission;
            if (!isSubmission) {
              history.push("/unauthorized");
            }
            if (isSubmission) {
              setUserAddedPredictions(res?.data?.submission);
              localStorage.setItem("isSavedSession", false);
            }
          });

        if (!isSubmission) {
          await getDataFromLocalForage();
        }
      } else {
        await getDataFromLocalForage();
      }
      setLoading(true);
    })();
  }, []);

  async function getDataFromLocalForage() {
    const isSavedSession = localStorage.getItem("isSavedSession");

    if (isSavedSession === "true") {
      const savedGroups = [
        await localForage.getItem("group_a"),
        await localForage.getItem("group_b"),
        await localForage.getItem("group_c"),
        await localForage.getItem("group_d"),
        await localForage.getItem("group_e"),
        await localForage.getItem("group_f"),
      ];

      const savedSelectedTeams = [
        await localForage.getItem("selectedSixteen1"),
        await localForage.getItem("selectedSixteen2"),
        await localForage.getItem("selectedSixteen3"),
        await localForage.getItem("selectedSixteen4"),
        await localForage.getItem("selectedSixteen5"),
        await localForage.getItem("selectedSixteen6"),
        await localForage.getItem("selectedSixteen7"),
        await localForage.getItem("selectedSixteen8"),
      ];
      const savedSelectedOptions = [
        { ...(await localForage.getItem("select03")), matchNum: 3 },
        { ...(await localForage.getItem("select04")), matchNum: 4 },
        { ...(await localForage.getItem("select06")), matchNum: 6 },
        { ...(await localForage.getItem("select08")), matchNum: 8 },
      ];
      const savedSelectedQuarter = [
        await localForage.getItem("selectedQuarter1"),
        await localForage.getItem("selectedQuarter2"),
        await localForage.getItem("selectedQuarter3"),
        await localForage.getItem("selectedQuarter4"),
      ];
      const savedSelectedSemi = [
        await localForage.getItem("selectedSemi1"),
        await localForage.getItem("selectedSemi2"),
      ];
      const savedSelectedFinal = [await localForage.getItem("selectedFinal")];

      const mapMatchesToServerData = [
        savedSelectedTeams,
        savedSelectedQuarter,
        savedSelectedSemi,
        savedSelectedFinal,
      ]
        .flat()
        .map((m, i) => {
          return {
            matchId: m.matchId,
            result: m.result,
            awayTeamFromGroup: savedSelectedOptions.find(
              (so) => so.matchNum === i + 1
            )?.fromGroup,
          };
        });

      const mapGroupResultToServerData = savedGroups.map((sg) => {
        return {
          TournamentGroupId: sg[0]?.groupId,
          GroupPredictionRankings: sg.map((team) => {
            return {
              ranking: team.rank,
              teamId: team.id,
            };
          }),
        };
      });

      setUserAddedPredictions({
        isLastSubmission: true,
        isFromLocalStorage: true,
        tournamentMatchPredictions: mapMatchesToServerData,
        groupPredictions: mapGroupResultToServerData,
      });
    }
    return;
  }

  useEffect(() => {
    setTimeout(() => {
      (async () => {})();
    }, 1000);
  }, [groups, knockOut]);

  const userAddedGroupPrediction = true;

  return (
    <TournamentContext.Provider
      value={{
        userAddedGroupPrediction,
        stageSize,
        setStageSize,
        stageIndex,
        setStageIndex,
        loading,
        groups,
        setGroupA,
        group_a,
        setGroupB,
        group_b,
        setGroupC,
        group_c,
        setGroupD,
        group_d,
        setGroupE,
        group_e,
        setGroupF,
        group_f,
        select03,
        setSelected03,
        select04,
        setSelected04,
        select06,
        setSelected06,
        select08,
        setSelected08,
        groupState,
        setGroupState,
        selectedSixteen1,
        setSelectedSixteen1,
        selectedSixteen2,
        setSelectedSixteen2,
        selectedSixteen3,
        setSelectedSixteen3,
        selectedSixteen4,
        setSelectedSixteen4,
        selectedSixteen5,
        setSelectedSixteen5,
        selectedSixteen6,
        setSelectedSixteen6,
        selectedSixteen7,
        setSelectedSixteen7,
        selectedSixteen8,
        setSelectedSixteen8,
        selectedQuarter1,
        setSelectedQuarter1,
        selectedQuarter2,
        setSelectedQuarter2,
        selectedQuarter3,
        setSelectedQuarter3,
        selectedQuarter4,
        setSelectedQuarter4,
        selectedSemi1,
        setSelectedSemi1,
        selectedSemi2,
        setSelectedSemi2,
        selectedFinal,
        setSelectedFinal,
        knockOut,
        selectedSixteenArr,
        selectedQuarterArr,
        selectedSemiArr,
        handleSubmit,
        postOrPut,
        userEditPressed,
        handleEdit,
        userAddedPredictions,
        tournamentPrediction,
        tournamentStartTime,
        isAfterEndOfGroupStage,
      }}
    >
      {children}
    </TournamentContext.Provider>
  );
}
