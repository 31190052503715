import React from "react";
import QuarterFinalsSection from "./QuarterFinalsSection";

import RoundOfSixteenSection from "./RoundOfSixteenSection";
import SemiFinalsSection from "./SemiFinalsSection";

const TeamsBoxKnockout = ({
  RoundOfSixteen,
  QuarterFinals,
  setGroupsSixteen,
}) => {
  if (RoundOfSixteen) {
    return <RoundOfSixteenSection setGroupsSixteen={setGroupsSixteen} />;
  } else if (QuarterFinals) {
    return <QuarterFinalsSection />;
  } else {
    return <SemiFinalsSection />;
  }
};

export default TeamsBoxKnockout;
