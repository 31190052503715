import React, { useContext } from "react";
import { TournamentContext } from "../../contexts/TournamentContext";
import KnockOutMatchBox from "./KnockOutMatchBox";

const SemiFinalsSection = () => {
  const {
    stageIndex,
    stageSize,
    selectedSemi2,
    selectedSemi1,
    setSelectedSemi1,
    setSelectedSemi2,
    knockOut,
    selectedQuarterArr,
    userAddedPredictions,
    userAddedGroupPrediction,
  } = useContext(TournamentContext);

  const semiFinalsMatchesFromServer = knockOut?.find(
    (stage) => stage?.name === "Semi-finals"
  )?.matches;

  const serverPrediction = userAddedPredictions?.tournamentMatchPredictions;

  const semiFinalMatches = knockOut?.find(
    (item) => item?.name === "Semi-finals"
  ).matches;

  const semiFinal_matchId = semiFinalMatches?.map((match) => match?.id);

  const serverMatch01 = serverPrediction?.find(
    (match) => match?.matchId === 140708
  );
  const serverMatch02 = serverPrediction?.find(
    (match) => match?.matchId === 140678
  );

  const homeTeams_prevMatchArr = semiFinalMatches?.map((matches) => {
    return matches?.homeTeamFrom?.previousPosition?.matchId;
  });
  const awayTeams_prevMatchArr = semiFinalMatches?.map((matches) => {
    return matches?.awayTeamFrom?.previousPosition?.matchId;
  });

  const first_match_homeTeam = selectedQuarterArr?.find(
    (item) => item?.matchId == homeTeams_prevMatchArr[0]
  );

  const first_match_awayTeam = selectedQuarterArr?.find(
    (item) => item?.matchId == awayTeams_prevMatchArr[0]
  );

  /////
  const second_match_homeTeam = selectedQuarterArr?.find(
    (item) => item?.matchId == homeTeams_prevMatchArr[1]
  );
  const second_match_awayTeam = selectedQuarterArr?.find(
    (item) => item?.matchId == awayTeams_prevMatchArr[1]
  );
  const lengthMerge = (val) => {
    if (val) {
      if (val[0] && val[1]) {
        return 2;
      } else if ((!val[0] && val[1]) || (!val[1] && val[0])) {
        return 1;
      } else {
        return 0;
      }
    } else return 0;
  };

  let offsetClass;
  if (stageIndex < 3 && userAddedGroupPrediction) {
    offsetClass = "offsetMatches";
  }

  if (stageIndex >= 3 && userAddedGroupPrediction) {
    offsetClass = "";
  }

  if (stageIndex >= 2 && !userAddedGroupPrediction) {
    offsetClass = "";
  }

  if (stageIndex < 2 && !userAddedGroupPrediction) {
    offsetClass = "offsetMatches";
  }

  return (
    <div className="teams-box-knockout">
      <div
        className={`matches-flex-container ${offsetClass}`}
        style={{ height: stageSize }}
      >
        <KnockOutMatchBox
          matchId={semiFinal_matchId[0]}
          finalRealMatchScore={
            semiFinalsMatchesFromServer?.find(
              (match) => match?.id === semiFinal_matchId[0]
            )?.matchScore
          }
          matchStatus={
            semiFinalsMatchesFromServer?.find(
              (match) => match?.id === semiFinal_matchId[0]
            )?.status_code
          }
          level={lengthMerge([first_match_homeTeam, first_match_awayTeam])}
          setValue={setSelectedSemi1}
          currentSelectedTeam={selectedSemi1}
          details
          data={[first_match_homeTeam, first_match_awayTeam]}
          serverData={serverMatch01}
        />
        <KnockOutMatchBox
          matchId={semiFinal_matchId[1]}
          finalRealMatchScore={
            semiFinalsMatchesFromServer?.find(
              (match) => match?.id === semiFinal_matchId[1]
            )?.matchScore
          }
          matchStatus={
            semiFinalsMatchesFromServer?.find(
              (match) => match?.id === semiFinal_matchId[1]
            )?.status_code
          }
          level={lengthMerge([second_match_homeTeam, second_match_awayTeam])}
          setValue={setSelectedSemi2}
          currentSelectedTeam={selectedSemi2}
          details
          data={[second_match_homeTeam, second_match_awayTeam]}
          serverData={serverMatch02}
        />
      </div>

      {/* <div className="next-stage"></div> */}
    </div>
  );
};
export default SemiFinalsSection;
