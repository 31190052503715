import React, { useContext, useEffect, useState } from "react";
import { TournamentContext } from "../../contexts/TournamentContext";
import ModalContainer from "../ModalContainer";
import KnockOutMatchBox from "./KnockOutMatchBox";

const RoundOfSixteenSection = ({ setGroupsSixteen }) => {
  const [a, setA] = useState(false);
  const [possibleTeamsToPick, setpossibleTeamsToPick] = useState([]);
  const {
    stageIndex,
    stageSize,
    group_a,
    group_b,
    group_c,
    group_d,
    group_e,
    group_f,
    select03,
    select04,
    select06,
    select08,
    selectedSixteen3,
    selectedSixteen4,
    selectedSixteen6,
    selectedSixteen8,
    selectedSixteen2,
    selectedSixteen5,
    selectedSixteen7,
    selectedSixteen1,
    setSelectedSixteen1,
    setSelectedSixteen2,
    setSelectedSixteen3,
    setSelectedSixteen4,
    setSelectedSixteen5,
    setSelectedSixteen6,
    setSelectedSixteen7,
    setSelectedSixteen8,
    setSelected03,
    setSelected04,
    setSelected06,
    setSelected08,
    userEditPressed,
    knockOut,
    userAddedPredictions,
    userAddedGroupPrediction,
  } = useContext(TournamentContext);

  const roundOfSixteenMatchesFromServer = knockOut?.find(
    (stage) => stage?.name === "1"
  )?.matches;

  const fix_array_3 = (a, b, c) => {
    let temp = [];
    if (a) {
      temp = [a, ...temp];
    }
    if (b) {
      temp = [b, ...temp];
    }
    if (c) {
      temp = [c, ...temp];
    }
    return temp;
  };
  const fix_array_4 = (a, b, c, d) => {
    let temp = [];
    if (a) {
      temp = [a, ...temp];
    }
    if (b) {
      temp = [b, ...temp];
    }
    if (c) {
      temp = [c, ...temp];
    }
    if (d) {
      temp = [d, ...temp];
    }
    return temp;
  };
  let start = false;
  // Game 1
  const a2 = group_a.find((item) => item.rank === 2);
  const b2 = group_b.find((item) => item.rank === 2);
  const merge_1 = [a2, b2];
  // Game 2
  const a1 = group_a.find((item) => item.rank === 1);
  const c2 = group_c.find((item) => item.rank === 2);
  const merge_2 = [a1, c2];
  // Game 3
  const c1_3 = group_c.find((item) => item.rank === 1);
  const d3_3 = group_d.find((item) => item.rank === 3);
  const e3_3 = group_e.find((item) => item.rank === 3);
  const f3_3 = group_f.find((item) => item.rank === 3);
  const arrGame3 = fix_array_3(d3_3, e3_3, f3_3);
  const [thirdTeamArr_match3, setThirdTeamArr_match3] = useState(arrGame3);

  useEffect(() => {
    setpossibleTeamsToPick(
      [
        d3_3,
        e3_3,
        f3_3,
        a3_4,
        d3_4,
        e3_4,
        f3_4,
        a3_6,
        b3_6,
        c3_6,
        a3_8,
        b3_8,
        c3_8,
        d3_8,
      ].map((team) => {
        const findTeamPick = possibleTeamsToPick.find(
          (teamPick) => teamPick.pickedBy
        );
        if (findTeamPick) return findTeamPick;
        return {
          team,
          pickedBy: null,
        };
      })
    );
  }, [
    d3_3,
    e3_3,
    f3_3,
    a3_4,
    d3_4,
    e3_4,
    f3_4,
    a3_6,
    b3_6,
    c3_6,
    a3_8,
    b3_8,
    c3_8,
    d3_8,
  ]);

  useEffect(() => {
    setThirdTeamArr_match3(arrGame3);
  }, [d3_3, e3_3, f3_3]);

  let merge_3 = [c1_3, select03];

  // Game 4
  const b1_4 = group_b.find((item) => item.rank === 1);
  const a3_4 = group_a.find((item) => item.rank === 3);
  const d3_4 = group_d.find((item) => item.rank === 3);
  const e3_4 = group_e.find((item) => item.rank === 3);
  const f3_4 = group_f.find((item) => item.rank === 3);
  const arrGame4 = fix_array_4(a3_4, d3_4, e3_4, f3_4);
  const [thirdTeamArr_match4, setThirdTeamArr_match4] = useState(arrGame4);

  useEffect(() => {
    setThirdTeamArr_match4(arrGame4);
  }, [a3_4, d3_4, e3_4, f3_4]);

  let merge_4 = [b1_4, select04];

  // Game 5
  const d2 = group_d.find((item) => item.rank === 2);
  const e2 = group_e.find((item) => item.rank === 2);
  const merge_5 = [d2, e2];
  // Game 6
  const f1_6 = group_f.find((item) => item.rank === 1);
  const a3_6 = group_a.find((item) => item.rank === 3);
  const b3_6 = group_b.find((item) => item.rank === 3);
  const c3_6 = group_c.find((item) => item.rank === 3);
  const arrGame6 = fix_array_3(a3_6, b3_6, c3_6);
  const [thirdTeamArr_match6, setThirdTeamArr_match6] = useState(arrGame6);

  useEffect(() => {
    setThirdTeamArr_match6(arrGame6);
  }, [a3_6, b3_6, c3_6]);

  let merge_6 = [f1_6, select06];

  // Game 7
  const d1 = group_d.find((item) => item.rank === 1);
  const f2 = group_f.find((item) => item.rank === 2);
  const merge_7 = [d1, f2];
  // Game 8
  const e1_8 = group_e.find((item) => item.rank === 1);
  const a3_8 = group_a.find((item) => item.rank === 3);
  const b3_8 = group_b.find((item) => item.rank === 3);
  const c3_8 = group_c.find((item) => item.rank === 3);
  const d3_8 = group_d.find((item) => item.rank === 3);
  const arrGame8 = fix_array_4(a3_8, b3_8, c3_8, d3_8);
  const [thirdTeamArr_match8, setThirdTeamArr_match8] = useState(arrGame8);

  //get data from server
  const serverPrediction = userAddedPredictions?.tournamentMatchPredictions;

  const serverMatch01 = serverPrediction?.find(
    (match) => match?.matchId === 140288
  );
  const serverMatch02 = serverPrediction?.find(
    (match) => match?.matchId === 140318
  );
  const serverMatch03 = serverPrediction?.find(
    (match) => match?.matchId === 140353
  );
  const serverMatch04 = serverPrediction?.find(
    (match) => match?.matchId === 140389
  );
  const serverMatch05 = serverPrediction?.find(
    (match) => match?.matchId === 140424
  );
  const serverMatch06 = serverPrediction?.find(
    (match) => match?.matchId === 140460
  );
  const serverMatch07 = serverPrediction?.find(
    (match) => match?.matchId === 140492
  );

  const serverMatch08 = serverPrediction?.find(
    (match) => match?.matchId === 140527
  );

  const thirdTeamServer03 = thirdTeamArr_match3?.find(
    (match) => match?.fromGroup === serverMatch03?.awayTeamFromGroup
  );

  const thirdTeamServer04 = thirdTeamArr_match4?.find(
    (match) => match?.fromGroup === serverMatch04?.awayTeamFromGroup
  );

  const thirdTeamServer06 = thirdTeamArr_match6?.find(
    (match) => match?.fromGroup === serverMatch06?.awayTeamFromGroup
  );
  const thirdTeamServer08 = thirdTeamArr_match8?.find(
    (match) => match?.fromGroup === serverMatch08?.awayTeamFromGroup
  );

  useEffect(() => {
    setThirdTeamArr_match8(arrGame8);
  }, [a3_8, b3_8, c3_8, d3_8]);

  let merge_8 = [e1_8, select08];

  //////here
  //selected 03
  useEffect(() => {
    console.log();
  }, [select03, select04, select06, select08]);

  useEffect(() => {
    const newPossibleTeamsToPick = [...possibleTeamsToPick];
    const findLastSelectedBy03 = newPossibleTeamsToPick.find(
      (t) => t.pickedBy === 3
    );
    if (findLastSelectedBy03) findLastSelectedBy03.pickedBy = null;
    if (select03) {
      const modifiedTeam = newPossibleTeamsToPick?.find(
        (teampick) => teampick?.team?.id === select03.id
      );
      if (modifiedTeam) {
        modifiedTeam.pickedBy = 3;
      }
      setpossibleTeamsToPick(newPossibleTeamsToPick);
    }
    if (selectedSixteen3?.id && select03?.id === selectedSixteen3?.id) {
      setSelectedSixteen3(null);
    }
  }, [select03]);

  useEffect(() => {
    const newPossibleTeamsToPick = [...possibleTeamsToPick];
    const findLastSelectedBy04 = newPossibleTeamsToPick.find(
      (t) => t.pickedBy === 4
    );
    if (findLastSelectedBy04) findLastSelectedBy04.pickedBy = null;
    if (select04) {
      const modifiedTeam = newPossibleTeamsToPick?.find(
        (teampick) => teampick?.team?.id === select04.id
      );
      if (modifiedTeam) {
        modifiedTeam.pickedBy = 4;
      }
      setpossibleTeamsToPick(newPossibleTeamsToPick);
    }
    if (selectedSixteen4?.id && select04?.id === selectedSixteen4?.id) {
      setSelectedSixteen4(null);
    }
  }, [select04]);

  useEffect(() => {
    const newPossibleTeamsToPick = [...possibleTeamsToPick];
    const findLastSelectedBy06 = newPossibleTeamsToPick.find(
      (t) => t.pickedBy === 6
    );
    if (findLastSelectedBy06) findLastSelectedBy06.pickedBy = null;
    if (select06) {
      const modifiedTeam = newPossibleTeamsToPick?.find(
        (teampick) => teampick?.team?.id === select06.id
      );
      if (modifiedTeam) {
        modifiedTeam.pickedBy = 6;
      }
      setpossibleTeamsToPick(newPossibleTeamsToPick);
    }
    if (selectedSixteen6?.id && select06?.id === selectedSixteen6?.id) {
      setSelectedSixteen6(null);
    }
  }, [select06]);

  useEffect(() => {
    const newPossibleTeamsToPick = [...possibleTeamsToPick];
    const findLastSelectedBy08 = newPossibleTeamsToPick.find(
      (t) => t.pickedBy === 8
    );
    if (findLastSelectedBy08) findLastSelectedBy08.pickedBy = null;
    if (select08) {
      const modifiedTeam = newPossibleTeamsToPick?.find(
        (teampick) => teampick?.team?.id === select08.id
      );
      if (modifiedTeam) {
        modifiedTeam.pickedBy = 8;
      }
      setpossibleTeamsToPick(newPossibleTeamsToPick);
    }
    if (selectedSixteen8?.id && select08?.id === selectedSixteen8?.id) {
      setSelectedSixteen8(null);
    }
  }, [select08]);

  //
  if (select03) {
    merge_3 = [c1_3, select03];
  } else if (thirdTeamServer03 && !userEditPressed) {
    merge_3 = [c1_3, thirdTeamServer03];
  }

  if (select04) {
    merge_4 = [b1_4, select04];
  } else if (thirdTeamServer04 && !userEditPressed) {
    merge_3 = [c1_3, thirdTeamServer04];
  }
  if (select06) {
    merge_6 = [f1_6, select06];
  } else if (thirdTeamServer06 && !userEditPressed) {
    merge_3 = [c1_3, thirdTeamServer06];
  }
  if (select08) {
    merge_8 = [e1_8, select08];
  }
  ////////////////////////////
  if (
    merge_1[0] &&
    merge_1[1] &&
    merge_2[0] &&
    merge_2[1] &&
    merge_5[0] &&
    merge_5[1] &&
    merge_7[0] &&
    merge_7[1] &&
    thirdTeamArr_match3?.length === 3 &&
    thirdTeamArr_match4?.length === 4 &&
    thirdTeamArr_match6?.length === 3 &&
    thirdTeamArr_match8?.length === 4
  ) {
    start = true;
  }
  if (!a) {
    if (
      merge_1.length === 2 &&
      merge_2.length === 2 &&
      merge_3.length === 2 &&
      merge_4.length === 2 &&
      merge_5.length === 2 &&
      merge_6.length === 2 &&
      merge_7.length === 2 &&
      merge_8.length === 2
    ) {
      setGroupsSixteen([
        merge_1,
        merge_2,
        merge_3,
        merge_4,
        merge_5,
        merge_6,
        merge_7,
        merge_8,
      ]);
      setA(true);
    }
  }

  const lengthMerge = (val) => {
    if (val) {
      if (val[0] && val[1]) {
        return 2;
      } else if ((!val[0] && val[1]) || (!val[1] && val[0])) {
        return 1;
      } else {
        return 0;
      }
    } else return 0;
  };

  let offsetClass;
  if (stageIndex < 1 && userAddedGroupPrediction) {
    offsetClass = "offsetMatches";
  }

  if (stageIndex >= 1 && userAddedGroupPrediction) {
    offsetClass = "";
  }

  if (!userAddedGroupPrediction) {
    offsetClass = "";
  }

  return (
    <div className="teams-box-knockout">
      <div
        className={`matches-flex-container ${offsetClass}`}
        style={{ height: stageSize }}
      >
        <KnockOutMatchBox
          matchId={140389}
          finalRealMatchScore={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140389
            )?.matchScore
          }
          matchStatus={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140389
            )?.status_code
          }
          setValue={setSelectedSixteen4}
          currentSelectedTeam={selectedSixteen4}
          details
          level={lengthMerge(merge_4)}
          data={merge_4}
          possible_teams_arr={thirdTeamArr_match4.filter((t) => {
            const teampick = possibleTeamsToPick.find(
              (teampick) => teampick?.team?.id === t.id
            );
            if (teampick?.pickedBy && teampick.pickedBy !== 4) return false;
            return true;
          })}
          setSelected={setSelected04}
          selected={select04}
          serverThirdTeamSelected={thirdTeamServer04}
          serverData={serverMatch04}
        />
        <KnockOutMatchBox
          matchId={140318}
          finalRealMatchScore={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140318
            )?.matchScore
          }
          matchStatus={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140318
            )?.status_code
          }
          setValue={setSelectedSixteen2}
          currentSelectedTeam={selectedSixteen2}
          details
          level={lengthMerge(merge_2)}
          data={merge_2}
          serverData={serverMatch02}
        />
        <KnockOutMatchBox
          matchId={140460}
          finalRealMatchScore={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140460
            )?.matchScore
          }
          matchStatus={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140460
            )?.status_code
          }
          setValue={setSelectedSixteen6}
          currentSelectedTeam={selectedSixteen6}
          details
          level={lengthMerge(merge_6)}
          data={merge_6}
          possible_teams_arr={thirdTeamArr_match6.filter((t) => {
            const teampick = possibleTeamsToPick.find(
              (teampick) => teampick?.team?.id === t.id
            );
            if (teampick?.pickedBy && teampick.pickedBy !== 6) return false;
            return true;
          })}
          setSelected={setSelected06}
          selected={select06}
          serverThirdTeamSelected={thirdTeamServer06}
          serverData={serverMatch06}
        />
        <KnockOutMatchBox
          matchId={140424}
          finalRealMatchScore={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140424
            )?.matchScore
          }
          matchStatus={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140424
            )?.status_code
          }
          setValue={setSelectedSixteen5}
          currentSelectedTeam={selectedSixteen5}
          details
          level={lengthMerge(merge_5)}
          data={merge_5}
          serverData={serverMatch05}
        />
        <KnockOutMatchBox
          matchId={140527}
          finalRealMatchScore={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140527
            )?.matchScore
          }
          matchStatus={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140527
            )?.status_code
          }
          setValue={setSelectedSixteen8}
          currentSelectedTeam={selectedSixteen8}
          details
          level={lengthMerge(merge_8)}
          data={merge_8}
          possible_teams_arr={thirdTeamArr_match8.filter((t) => {
            const teampick = possibleTeamsToPick.find(
              (teampick) => teampick?.team?.id === t.id
            );
            if (teampick?.pickedBy && teampick.pickedBy !== 8) return false;
            return true;
          })}
          setSelected={setSelected08}
          selected={select08}
          serverThirdTeamSelected={thirdTeamServer08}
          serverData={serverMatch08}
        />
        <KnockOutMatchBox
          matchId={140492}
          finalRealMatchScore={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140492
            )?.matchScore
          }
          matchStatus={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140492
            )?.status_code
          }
          setValue={setSelectedSixteen7}
          currentSelectedTeam={selectedSixteen7}
          details
          level={lengthMerge(merge_7)}
          data={merge_7}
          serverData={serverMatch07}
        />
        <KnockOutMatchBox
          matchId={140353}
          finalRealMatchScore={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140353
            )?.matchScore
          }
          matchStatus={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140353
            )?.status_code
          }
          setValue={setSelectedSixteen3}
          currentSelectedTeam={selectedSixteen3}
          details
          level={lengthMerge(merge_3)}
          data={merge_3}
          possible_teams_arr={thirdTeamArr_match3.filter((t) => {
            const teampick = possibleTeamsToPick.find(
              (teampick) => teampick?.team?.id === t.id
            );
            if (teampick?.pickedBy && teampick.pickedBy !== 3) return false;
            return true;
          })}
          setSelected={setSelected03}
          selected={select03}
          serverThirdTeamSelected={thirdTeamServer03}
          serverData={serverMatch03}
        />
        <KnockOutMatchBox
          matchId={140288}
          finalRealMatchScore={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140288
            )?.matchScore
          }
          matchStatus={
            roundOfSixteenMatchesFromServer?.find(
              (match) => match?.id === 140288
            )?.status_code
          }
          setValue={setSelectedSixteen1}
          currentSelectedTeam={selectedSixteen1}
          details
          level={lengthMerge(merge_1)}
          data={merge_1}
          serverData={serverMatch01}
        />
      </div>
    </div>
  );
};
export default RoundOfSixteenSection;
