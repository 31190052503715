import React, { useState } from "react";
import { Modal, Fade, makeStyles, Backdrop } from "@material-ui/core";
import RowTournamentThree from "../RowTournamentThree";
import RowTournamentFour from "../RowTournamentFour";

const backgroundCheckedHome = {
  background: "linear-gradient(91.47deg, #508CE3 0.5%, #4D678D 100%)",
  borderRadius: "20px 20px 0 0",
  height: "100%",
};
const backgroundCheckedAway = {
  background: "linear-gradient(91.47deg, #508CE3 0.5%, #4D678D 100%)",
  borderRadius: "0px 0px 20px 20px",
  height: "100%",
};
const normalWrap = {
  background: "linear-gradient(91.47deg, #508CE3 0.5%, #4D678D 100%)",
  height: "100%",
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    width: "100%",
  },
  container_btn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "20px",
  },
  btn: {
    "&:hover, &active, &focus": {
      backgroundColor: "#7ebc15",
      color: "white",
      outline: "none",
    },
    width: "80px",
    height: "30px",
    border: "1px solid #7ebc15",
    borderRadius: "20px",
    color: "#7ebc15",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ModalTournament = ({
  steps,
  team,
  setCurrent,
  isFinish,
  setSelectValue,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const [finishModal, setFinishModal] = useState(false);

  if (finishModal) {
    return null;
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              marginRight: "75%",
              // marginTop: "150%",
              justifyContent: "center",
              flexDirection: "column",
              width: "22%",
              height: "50%",
            }}
          >
            <p
              style={{ fontSize: "16px", color: "white", whiteSpace: "nowrap" }}
            >
              Please choose the competitor for: <br /> {team?.teamName}
            </p>
            {steps.length === 3 ? (
              <RowTournamentThree
                setSelectValue={setSelectValue}
                steps={steps}
                setFinish={setFinishModal}
                setCurrent={setCurrent}
                isFinish={isFinish}
                style={classes.paper}
              />
            ) : (
              <RowTournamentFour
                setSelectValue={setSelectValue}
                steps={steps}
                setFinish={setFinishModal}
                setCurrent={setCurrent}
                isFinish={isFinish}
                style={classes.paper}
              />
            )}
          </div>
        </Fade>
      </Modal>
    );
  }
};
export default ModalTournament;
