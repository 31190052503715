import { useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";
import unauthPhoto from "./images/unauthorized.jpg";
import unauthPhotoDesktop from "./images/unauthorizedDesktop.jpg";

// backgroundImage: `url(${unauthPhoto})`,
// height: "100vh",
// width: "100vw",
// backgroundRepeat: "no-repeat",
// backgroundPosition: "center",
// backgroundSize: "cover",

const UnAuthorizedUser = () => {
  useEffect(() => {
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#334048";
    return () => {
      document.body.style.margin = "5rem 0 0";
      document.body.style.backgroundColor = "unset";
    }
  }, []);
  const isDesktop = useMediaQuery('(min-width:500px)');
  const handleClick = () => {
    window.parent.location.href =
      "https://m.apuestas.codere.es/#/HomePage?openlogin=true&afterloginurl=CodereMillionPage";
  };
  return (
    <div
      style={{
        width: "100vw",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <img alt="background img" src={isDesktop ? unauthPhotoDesktop : unauthPhoto} style={{
        zIndex: -100, position: "relative",
        ...(isDesktop ?
          { width: "1900px", transform: "translateX(calc((100% - 100vw)/-2))" } :
          { width: "100vw" })
      }} />
      <div style={{ position: "absolute", zIndex: 100, top: 0, bottom: 0, right: 0, left: 0 }}>
        <div
          style={{
            width: "20em",
            borderRadius: 10,
            background: "#3B5E6C",
            color: "white",
            visibility: "hidden",
          }}
          className="container text-center"
        >
          <p>
            Has metido tus picks de la Eurocopa? Ingresa en tu cuenta y sigue con
            la diversion
        </p>
        </div>
        <div
          className="container text-center"
          style={{
            marginTop: "10em",
            width: "20em",
            borderRadius: 10,
            background: "#3B5E6C",
            color: "white",
            border: "solid black 2px",
          }}
        >
          <p>
            Has metido tus picks de la Eurocopa? Ingresa en tu cuenta y sigue con
            la diversion
        </p>
        </div>
        <button
          onClick={handleClick}
          className="button"
          style={{
            marginTop: "1em",
            color: "white",
            border: "black solid 1px",
            background: "#1472ff",
          }}
        >
          ACCEDER
      </button>
      </div>
    </div>
  );
};

export default UnAuthorizedUser;
