import React, { useContext, useEffect, useState } from "react";
import KnockOutMatchBox from "./KnockOutMatchBox";
import { TournamentContext } from "../../contexts/TournamentContext";
import TournamentHeadline from "../headers/TournamentHeadline";
import TournamentSubmitModal from "./TournamentSubmitModal";
import viLarge from "../images/viLarge.png";
import { Checkbox } from "@material-ui/core";
import finalWinner from "../images/final_winner.png";

const originalFinalFlagImg =
  "https://codereappimagesbucket.s3.us-east-2.amazonaws.com/final_flags/1question.png";

const loadImgAhead = new Image();
loadImgAhead.src = originalFinalFlagImg; //to load the final flag ahead

const Final = () => {
  const {
    stageIndex,
    selectedSemi1,
    selectedSemi2,
    selectedFinal,
    setSelectedFinal,
    knockOut,
    selectedSemiArr,
    handleSubmit,
    stageSize,
    group_a,
    group_b,
    group_c,
    group_d,
    group_e,
    group_f,
    select03,
    select04,
    select06,
    select08,
    selectedQuarter1,
    selectedQuarter2,
    selectedQuarter3,
    selectedQuarter4,
    userAddedPredictions,
    postOrPut,
    userEditPressed,
    userAddedGroupPrediction,
  } = useContext(TournamentContext);

  const [editModelOpened, setEditModelOpened] = useState(false);
  const [agreeChecked, setAgreeChecked] = useState(false);

  const finalMatchesFromServer = knockOut?.find(
    (stage) => stage?.name === "Final"
  )?.matches;

  console.log(finalMatchesFromServer);

  useEffect(() => {
    if (
      selectedFinal &&
      selectedSemi1 &&
      selectedSemi2 &&
      selectedFinal?.id !== selectedSemi1?.id &&
      selectedFinal?.id !== selectedSemi2?.id &&
      userEditPressed
    ) {
      setSelectedFinal(null);
    }
  }, [selectedSemi1, selectedSemi2, selectedFinal]);

  const finalMatch = knockOut?.find((item) => item?.name === "Final").matches;

  // const final_matchId = finalMatch?.map((match) => match?.id);
  const final_matchId = finalMatch[0]?.id;

  const serverPrediction = userAddedPredictions?.tournamentMatchPredictions;
  const serverMatch01 = serverPrediction?.find(
    (match) => match?.matchId === 140739
  );

  const homeTeams_prevMatchArr = finalMatch?.map((matches) => {
    return matches?.homeTeamFrom?.previousPosition?.matchId;
  });
  const awayTeams_prevMatchArr = finalMatch?.map((matches) => {
    return matches?.awayTeamFrom?.previousPosition?.matchId;
  });

  const match_homeTeam = selectedSemiArr?.find(
    (item) => item?.matchId == homeTeams_prevMatchArr[0]
  );

  const match_awayTeam = selectedSemiArr?.find(
    (item) => item?.matchId == awayTeams_prevMatchArr[0]
  );

  const lengthMerge = (val) => {
    if (val) {
      if (val[0] && val[1]) {
        return 2;
      } else if ((!val[0] && val[1]) || (!val[1] && val[0])) {
        return 1;
      } else {
        return 0;
      }
    } else return 0;
  };

  const formFilled =
    [group_a, group_b, group_c, group_d, group_e, group_f].every(
      (g) => g.length === 3
    ) &&
    [
      select03,
      select04,
      select06,
      select08,
      selectedQuarter1,
      selectedQuarter2,
      selectedQuarter3,
      selectedQuarter4,
      selectedSemi1,
      selectedSemi2,
      selectedFinal,
    ].every((t) => t?.id);

  const agreeCheckDisabled =
    (!userEditPressed && postOrPut === "put") || !formFilled;
  const submitDisabled =
    (!userEditPressed && postOrPut === "put") || !formFilled || !agreeChecked;

  const savedToken = sessionStorage.getItem("token");
  const token =
    savedToken?.length && +savedToken !== 0 && savedToken !== "null"
      ? savedToken
      : null;
  const completed = !!selectedFinal;
  return (
    <div style={{ position: "relative" }}>
      <div>
        <TournamentHeadline
          headline={"FINAL"}
          paragraph={`Y por último, ¿quién ganará la Eurocopa 2021? 
          ¡Mucha suerte!`}
          completed={completed}
        />
      </div>

      <div className="final-div">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                height: stageIndex < 4 ? 0 : "95vw",
                transition: "height 500ms",
                overflow: "visible",
                marginTop: "-1rem",
                width: "100%",
              }}
            >
              {selectedSemi1 && selectedSemi2 ? (
                <div style={{ height: "95vw", position: "relative" }}>
                  <div
                    style={{
                      height: "calc(100% - 60vw)",
                      fontSize: "1.5rem",
                      fontWeight: 500,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h2 className="font-face-Myriad">EUROCOPA 2020 GANADOR</h2>
                    <div
                      style={{ height: 0.7, width: 137, background: "#5cb5c9" }}
                    ></div>
                    {/* <img src={finalWinner} /> */}
                    <div>{selectedFinal?.teamName || ""}</div>
                  </div>
                  <img
                    className={"finalFlagImg"}
                    src={selectedFinal?.flagFinalImg || originalFinalFlagImg}
                    alt=""
                  />
                </div>
              ) : null}
            </div>
            <div
              className={`final-match-container ${
                stageIndex < 4
                  ? "offsetFinalMatch"
                  : userAddedGroupPrediction && stageIndex <= 3
                  ? "offsetFinalMatch_not_filled_groups"
                  : ""
              }`}
            >
              <div style={{ height: 0, alignSelf: "center" }}></div>
              <div style={{ height: 0 }}>
                <KnockOutMatchBox
                  matchId={final_matchId}
                  finalRealMatchScore={
                    finalMatchesFromServer?.find(
                      (match) => match?.id === final_matchId
                    )?.matchScore
                  }
                  matchStatus={
                    finalMatchesFromServer?.find(
                      (match) => match?.id === final_matchId
                    )?.status_code
                  }
                  level={lengthMerge([match_homeTeam, match_awayTeam])}
                  details
                  setValue={setSelectedFinal}
                  data={[match_homeTeam, match_awayTeam]}
                  finalServerData={serverMatch01}
                  currentSelectedTeam={selectedFinal}
                />
              </div>
              <div style={{ height: 0 }}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="submitContainer">
        <p style={{ fontSize: "0.8rem", textAlign: "center" }}>
          Información sobre protección de datos: CODERE ONLINE, S.A.U., como
          Responsable del tratamiento, tratará sus datos personales para
          gestionar la promoción y, eventualmente, realizar informes
          estadísticos. Puede ejercer sus derechos de acceso, rectificación,
          supresión, limitación del tratamiento portabilidad, oposición y no ser
          objeto de decisiones automatizadas escribiéndonos a
          protección.datos.es@codere.com
        </p>
        <p style={{ whiteSpace: "nowrap", fontSize: ".8rem" }}>
          <span>
            <Checkbox
              style={{ position: "relative", bottom: "2px" }}
              checked={agreeChecked}
              onChange={() => setAgreeChecked(!agreeChecked)}
              color="primary"
              inputProps={{ "aria-label": "terms and conditions checkbox" }}
              size="small"
              disabled={agreeCheckDisabled}
            />
            He leído y acepto los{" "}
            <a
              className="textLink"
              href="https://www.codere.es/PromocionesTYC/01.06.21.MillonCodere.html"
              target="_blank"
            >
              Términos y Condiciones
            </a>
          </span>
        </p>
        <button
          onClick={() => {
            postOrPut === "post" || !token
              ? handleSubmit().then(() => {
                  /*TODO: after submit flow goes here */
                })
              : setEditModelOpened(true);
          }}
          className={`button ${submitDisabled ? "button_disabled" : ""}`}
          disabled={submitDisabled}
        >
          Enviar
        </button>
      </div>
      <TournamentSubmitModal
        handleOk={async () => {
          await handleSubmit();
          setEditModelOpened(false);
          return;
        }}
        handleClose={() => {
          setEditModelOpened(false);
        }}
        opened={editModelOpened}
      />
    </div>
  );
};

export default Final;
