import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import tooltip from "../images/Question-Mark.png";

export default function TriggersTooltips({ fromGroups }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const longText = `
  ¿Estás listo para ganar 1 millón de euros? Ponte cómodo y a por ello. Lo primero que tienes que hacer es seleccionar en cada grupo quién queda primero, segundo y tercero al finalizar la fase de grupos. Cuando lo tengas, desliza la pantalla a la izquierda y selecciona qué selecciones pasan de ronda hasta llegar al campeón de la Euro. Ten en cuenta que de los 6 terceros que pasan sólo pasan a octavos los 4 primeros.
  Podrás editar tus pronósticos tantas veces como quieras hasta antes de que comience el campeonato. Prepárate y… ¡a por el millón!`;

  return (
    <div style={!fromGroups ? { display: "none" } : null}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={longText}
          >
            <Button onClose={handleTooltipClose} onClick={handleTooltipOpen}>
              <div className="d-flex">
                <span style={{ fontWeight: "bolder" }}>
                  ¿cómo jugar?{" "}
                  <img
                    src={tooltip}
                    style={{ display: "inline", width: 20 }}
                    alt="tooltip"
                  />
                </span>
              </div>
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}
