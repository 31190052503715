import React, { useContext, useEffect, useState } from "react";
import checkedVi from "../images/smallVi.png";
import checkedViDisabled from "../images/smallViDisabled.png";
import unCheckedVi from "../images/emptyVi.png";
import questionMark from "../images/questionMark.svg";
import bracketsEmpty from "../images/bracketsEmpty.svg";
import bracketsHalf from "../images/bracketsHalf.svg";
import bracketsFull from "../images/bracketsFull.svg";
import detailsImg from "../images/Details.svg";
import KnockOutOptionSelect from "./KnockOutOptionSelect";
import isNullOrUndefined from "./../../helpers/is-null-or-undefined";
import { TournamentContext } from "../../contexts/TournamentContext";
import TeamDetails from "../GeneralComponents/TeamDetails";
import MoreDetails from "../GeneralComponents/MoreDetails";
import CheckedVi from "./CheckedVi";
import successViKnockOut from "../images/successViKnockOut.png";
import failViKnockOut from "../images/failViKnockOut.png";
import { matchStatusEnum } from "../matchStatus";

const backgroundCheckedHome = {
  background: "linear-gradient(91.47deg, #508CE3 0.5%, #4D678D 100%)",
  borderRadius: "20px 20px 0 0",
  height: "100%",
};
const backgroundCheckedAway = {
  ...backgroundCheckedHome,
  borderRadius: "0px 0px 20px 20px",
};

const backgroundCheckedHome_gray = {
  ...backgroundCheckedHome,
  background: "#CFCFCF",
};

const backgroundCheckedAway_gray = {
  ...backgroundCheckedAway,
  background: "#CFCFCF",
};

const noBtnStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  paddingLeft: "1rem",
  position: "relative",
  transform: "translateY(calc(-100% - 1rem))",
};

const btnStyle = {
  ...noBtnStyle,
  transform: "translateY(calc(-76% - 1rem))",
};

function KnockOutMatchBox({
  data,
  level,
  details = false,
  setValue,
  possible_teams_arr,
  setSelected,
  selected,
  matchId,
  serverThirdTeamSelected,
  serverData,
  finalServerData,
  currentSelectedTeam,
  finalRealMatchScore, //real score from server
  matchStatus,
}) {
  // console.log(currentSelectedTeam);
  const [HomeChecked, setHomeChecked] = useState(false);
  const [AwayChecked, SetAwayChecked] = useState(false);

  const team1 = data[0];
  const team2 = data[1];

  const { userAddedPredictions, userEditPressed, postOrPut } =
    useContext(TournamentContext);

  // console.log(serverData);

  const demoRealScore = {
    id: 1393,
    home_score: 2,
    away_score: 4,
    createdAt: "2021-05-07T20:00:08.000Z",
    updatedAt: "2021-05-07T21:00:08.000Z",
    matchId: 168756,
  };

  const hasMatchEndedYet = (matchCode) => {
    return [
      matchStatusEnum.Ended,
      matchStatusEnum.AfterExtraTime,
      matchStatusEnum.AfterPenalties,
    ].includes(matchCode);
  };

  const userResultPrediction = serverData?.result;
  let WhoWon;
  let successOrFailure;

  if (hasMatchEndedYet(matchStatus)) {
    if (finalRealMatchScore?.home_score > finalRealMatchScore?.away_score) {
      WhoWon = "home_win";
    }

    if (finalRealMatchScore?.home_score < finalRealMatchScore?.away_score) {
      WhoWon = "away_win";
    }

    if (serverData?.status === 'success' || serverData?.status === 'failure') {
      if (serverData?.status === 'success') {
        successOrFailure = successViKnockOut;
      } else {
        successOrFailure = failViKnockOut;
      }
    }
  }

  // console.log(WhoWon);

  let homeBackgroundState;
  let awayBackgroundState;
  let checkedViState;
  if (postOrPut === "put" && !userEditPressed) {
    homeBackgroundState = backgroundCheckedHome_gray;
    awayBackgroundState = backgroundCheckedAway_gray;
    checkedViState = checkedViDisabled;
  }

  if (postOrPut === "put" && userEditPressed) {
    homeBackgroundState = backgroundCheckedHome;
    awayBackgroundState = backgroundCheckedAway;

    checkedViState = checkedVi;
  }
  if (postOrPut === "post") {
    homeBackgroundState = backgroundCheckedHome;
    awayBackgroundState = backgroundCheckedAway;

    checkedViState = checkedVi;
  }

  const whoWon = serverData?.result || finalServerData?.result;

  const deselectBothTeams = () => {
    setHomeChecked(false);
    SetAwayChecked(false);
    setValue(null);
    // setSelected(null);
  };

  useEffect(() => {
    if (!team1 && possible_teams_arr) {
      deselectBothTeams();
      setSelected(null);
    } else {
      if (
        (!team1 && HomeChecked) ||
        !currentSelectedTeam ||
        (HomeChecked &&
          currentSelectedTeam &&
          currentSelectedTeam.id !== team1.id)
      ) {
        setHomeChecked(false);
        setValue(null);
      }
      if (
        (!team2 && AwayChecked) ||
        !currentSelectedTeam ||
        (AwayChecked &&
          currentSelectedTeam &&
          currentSelectedTeam.id !== team2.id)
      ) {
        SetAwayChecked(false);
        setValue(null);
      }
    }
  }, [data]);

  useEffect(() => {
    if (possible_teams_arr && !selected && AwayChecked) {
      deselectBothTeams();
    }
  }, [serverThirdTeamSelected, selected, setSelected, AwayChecked]);

  function checkedOrUnchecked(check) {
    if (check === "home" || check === "home_win") {
      if (HomeChecked) {
        deselectBothTeams();
        return;
      }

      setHomeChecked(!HomeChecked);
      SetAwayChecked(false);
      setValue({ ...team1, result: "home_win", matchId: matchId });
    } else {
      if (possible_teams_arr && !selected) {
        return;
      }
      if (AwayChecked) {
        deselectBothTeams();
        return;
      }
      SetAwayChecked(!AwayChecked);
      setHomeChecked(false);
      setValue({ ...team2, result: "away_win", matchId: matchId });
    }
  }

  const handleClick = (homeOrAway) => {
    if (postOrPut === "put") {
      if (userEditPressed) {
        checkedOrUnchecked(homeOrAway);
      } else {
        return null;
      }
    } else {
      checkedOrUnchecked(homeOrAway);
    }
  };

  const checkWinFromServer = (whoWon) => {
    if (whoWon === "away_win") {
      SetAwayChecked(true);
      setValue({ ...team2, result: "away_win", matchId: matchId });
    }
    if (whoWon === "home_win") {
      setHomeChecked(true);
      setValue({ ...team1, result: "home_win", matchId: matchId });
    }
  };

  useEffect(() => {
    if (userEditPressed) return;
    checkWinFromServer(whoWon);
  }, [finalServerData, serverData, whoWon, team1, team2]);

  let bracketImg;
  if (level === 0) {
    bracketImg = bracketsEmpty;
  }
  if (level === 1) {
    bracketImg = bracketsHalf;
  }
  if (level === 2) {
    bracketImg = bracketsFull;
  }

  const bracketImgElement = (
    <img
      src={bracketImg}
      alt="brackets"
      style={{
        transform: level === 1 && team2 ? "rotateX(180deg)" : null,
      }}
    />
  );

  const questionMarkImg = (
    <img
      width="25px"
      height="25px"
      style={{ margin: 10 }}
      src={questionMark}
      alt="vi"
    />
  );

  const backgroundStylingChecked = (homeOrAway) => {
    if (homeOrAway === "home") {
      if (HomeChecked) {
        return {
          ...homeBackgroundState,
          flex: 1,
          paddingLeft: "1rem",
          paddingRight: "1rem",
        };
      } else return { flex: 1, paddingLeft: "1rem", paddingRight: "1rem" };
    }

    if ((homeOrAway = "away")) {
      if (AwayChecked) {
        return {
          ...awayBackgroundState,
          flex: 1,
          paddingLeft: "1rem",
          paddingRight: "1rem",
        };
      } else return { flex: 1, paddingLeft: "1rem", paddingRight: "1rem" };
    }
  };

  return (
    <div style={{ height: 0 }}>
      <div style={btnStyle}>
        <div className="d-flex">
          {bracketImgElement}
          <div className="knock-out-match-box">
            {/* color selected home team */}
            {!isNullOrUndefined(data && team1) ? (
              <div
                style={backgroundStylingChecked("home")}
                onClick={() => handleClick("home")}
                className={
                  "d-flex align-items-center border-bottom" +
                  (HomeChecked ? " checkedTeam" : "")
                }
              >
                <TeamDetails
                  team={team1}
                  HomeChecked={HomeChecked}
                  flagImage={team1?.flagImage}
                />
                <CheckedVi
                  home
                  checkedViState={checkedViState}
                  HomeChecked={HomeChecked}
                />
              </div>
            ) : (
              <div
                style={{ borderRadius: "20px 20px 0 0", flex: 1 }}
                className="row-empty"
              >
                {questionMarkImg}
              </div>
            )}

            {/* away team styling */}
            {(data && !isNullOrUndefined(team2)) ||
              (possible_teams_arr?.length && team1) ? (
              <div
                style={backgroundStylingChecked("away")}
                onClick={() => handleClick("away")}
                className={
                  "d-flex align-items-center" +
                  (AwayChecked ? " checkedTeam" : "")
                }
              >
                <TeamDetails
                  // team={team2}
                  AwayChecked={AwayChecked}
                  flagImage={team2?.flagImage}
                />
                <div
                  style={AwayChecked ? { color: "white" } : null}
                  className="ml-2"
                >
                  {team1 && team2 ? (
                    possible_teams_arr ? (
                      <KnockOutOptionSelect
                        homeTeam={team1}
                        possible_teams_arr={possible_teams_arr}
                        setSelected={setSelected}
                        selected={selected}
                        serverSelected={serverThirdTeamSelected}
                      />
                    ) : (
                      team2?.teamName
                    )
                  ) : team1 && possible_teams_arr ? (
                    <KnockOutOptionSelect
                      homeTeam={team1}
                      possible_teams_arr={possible_teams_arr}
                      setSelected={setSelected}
                      selected={selected}
                      serverSelected={serverThirdTeamSelected}
                    />
                  ) : (
                    team2?.teamName
                  )}
                </div>
                {(!possible_teams_arr || selected) && (
                  <CheckedVi
                    away
                    checkedViState={checkedViState}
                    AwayChecked={AwayChecked}
                  />
                )}
              </div>
            ) : (
              <div
                style={{ borderRadius: "0 0 20px 20px", flex: 1 }}
                className="row-empty"
              >
                {questionMarkImg}
              </div>
            )}
          </div>
        </div>
        {successOrFailure && (
          <img
            style={{ position: "absolute", right: "0", transform: "translateX(50%)" }}
            src={successOrFailure}
            alt="success or failure"
          />
        )}

        <MoreDetails details={details} />
      </div>
    </div>
  );
}

export default KnockOutMatchBox;
