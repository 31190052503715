import React, { useContext } from "react";
import TeamsBoxKnockout from "./TeamsBoxKnockout";
import TournamentHeadline from "../headers/TournamentHeadline";
import { TournamentContext } from "../../contexts/TournamentContext";

const SemiFinals = () => {
  const { stageSize, selectedSemi1, selectedSemi2, } = useContext(TournamentContext);
  const completed = [selectedSemi1, selectedSemi2].every(s => !!s)
  return (
    <div>
      <TournamentHeadline
        headline={"SEMIFINALES"}
        paragraph={"Estás muy cerca... Elige quiénes llegan a la final."}
        completed={completed}
      />
      <div className="matches-flex-container" style={{ height: stageSize }}>
        <TeamsBoxKnockout SemiFinals />
      </div>
    </div>
  );
};

export default SemiFinals;
