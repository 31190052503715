import React, { useContext, useEffect } from "react";
import KnockOutMatchBox from "./KnockOutMatchBox";
import { TournamentContext } from "../../contexts/TournamentContext";

const QuarterFinalsSection = () => {
  const {
    stageIndex,
    stageSize,
    selectedQuarter1,
    selectedQuarter2,
    selectedQuarter3,
    selectedQuarter4,
    setSelectedQuarter1,
    setSelectedQuarter2,
    setSelectedQuarter3,
    setSelectedQuarter4,
    selectedSixteenArr,
    knockOut,
    userAddedPredictions,
    userAddedGroupPrediction,
  } = useContext(TournamentContext);

  const quarteFinalsMatchesFromServer = knockOut?.find(
    (stage) => stage?.name === "Quarter-finals"
  )?.matches;

  const serverPrediction = userAddedPredictions?.tournamentMatchPredictions;

  const serverMatch02 = serverPrediction?.find(
    (match) => match?.matchId === 140586
  );
  const serverMatch04 = serverPrediction?.find(
    (match) => match?.matchId === 140558
  );
  const serverMatch01 = serverPrediction?.find(
    (match) => match?.matchId === 140649
  );
  const serverMatch03 = serverPrediction?.find(
    (match) => match?.matchId === 140618
  );

  useEffect(() => {
    for (const [setSelected, selected] of [
      [setSelectedQuarter1, selectedQuarter1],
      [setSelectedQuarter2, selectedQuarter2],
      [setSelectedQuarter3, selectedQuarter3],
      [setSelectedQuarter4, selectedQuarter4],
    ]) {
      if (
        selected &&
        !selectedSixteenArr.find((t) => t && t.id === selected.id)
      ) {
        setSelected(null);
      }
    }
  }, [selectedSixteenArr]);

  const quarterFinalMatches = knockOut?.find(
    (item) => item?.name === "Quarter-finals"
  ).matches;

  const quarterFinal_matchId = quarterFinalMatches?.map((match) => match?.id);

  const homeTeams_prevMatchArr = quarterFinalMatches?.map((matches) => {
    return matches?.homeTeamFrom?.previousPosition?.matchId;
  });
  const awayTeams_prevMatchArr = quarterFinalMatches?.map((matches) => {
    return matches?.awayTeamFrom?.previousPosition?.matchId;
  });

  const first_match_homeTeam = selectedSixteenArr?.find(
    (item) => item?.matchId == homeTeams_prevMatchArr[0]
  );

  const first_match_awayTeam = selectedSixteenArr?.find(
    (item) => item?.matchId == awayTeams_prevMatchArr[0]
  );

  /////
  const second_match_homeTeam = selectedSixteenArr?.find(
    (item) => item?.matchId == homeTeams_prevMatchArr[1]
  );
  const second_match_awayTeam = selectedSixteenArr?.find(
    (item) => item?.matchId == awayTeams_prevMatchArr[1]
  );
  //////
  const third_match_homeTeam = selectedSixteenArr?.find(
    (item) => item?.matchId == homeTeams_prevMatchArr[2]
  );
  const third_match_awayTeam = selectedSixteenArr?.find(
    (item) => item?.matchId == awayTeams_prevMatchArr[2]
  );
  /////
  const forth_match_homeTeam = selectedSixteenArr?.find(
    (item) => item?.matchId == homeTeams_prevMatchArr[3]
  );
  const forth_match_awayTeam = selectedSixteenArr?.find(
    (item) => item?.matchId == awayTeams_prevMatchArr[3]
  );

  const lengthMerge = (val) => {
    if (val) {
      if (val[0] && val[1]) {
        return 2;
      } else if ((!val[0] && val[1]) || (!val[1] && val[0])) {
        return 1;
      } else {
        return 0;
      }
    } else return 0;
  };

  let offsetClass;
  if (stageIndex < 2 && userAddedGroupPrediction) {
    offsetClass = "offsetMatches";
  }

  if (stageIndex >= 2 && userAddedGroupPrediction) {
    offsetClass = "";
  }

  if (stageIndex >= 1 && !userAddedGroupPrediction) {
    offsetClass = "";
  }

  if (stageIndex < 1 && !userAddedGroupPrediction) {
    offsetClass = "offsetMatches";
  }

  return (
    <div className="teams-box-knockout">
      <div
        className={`matches-flex-container ${offsetClass}`}
        style={{ height: stageSize }}
      >
        <KnockOutMatchBox
          matchId={quarterFinal_matchId[3]}
          finalRealMatchScore={
            quarteFinalsMatchesFromServer?.find(
              (match) => match?.id === quarterFinal_matchId[3]
            )?.matchScore
          }
          matchStatus={
            quarteFinalsMatchesFromServer?.find(
              (match) => match?.id === quarterFinal_matchId[3]
            )?.status_code
          }
          level={lengthMerge([forth_match_homeTeam, forth_match_awayTeam])}
          setValue={setSelectedQuarter4}
          currentSelectedTeam={selectedQuarter4}
          details
          data={[forth_match_homeTeam, forth_match_awayTeam]}
          serverData={serverMatch04}
        />
        <KnockOutMatchBox
          matchId={quarterFinal_matchId[0]}
          finalRealMatchScore={
            quarteFinalsMatchesFromServer?.find(
              (match) => match?.id === quarterFinal_matchId[0]
            )?.matchScore
          }
          matchStatus={
            quarteFinalsMatchesFromServer?.find(
              (match) => match?.id === quarterFinal_matchId[0]
            )?.status_code
          }
          level={lengthMerge([first_match_homeTeam, first_match_awayTeam])}
          setValue={setSelectedQuarter1}
          currentSelectedTeam={selectedQuarter1}
          details
          data={[first_match_homeTeam, first_match_awayTeam]}
          serverData={serverMatch01}
        />
        <KnockOutMatchBox
          matchId={quarterFinal_matchId[1]}
          finalRealMatchScore={
            quarteFinalsMatchesFromServer?.find(
              (match) => match?.id === quarterFinal_matchId[1]
            )?.matchScore
          }
          matchStatus={
            quarteFinalsMatchesFromServer?.find(
              (match) => match?.id === quarterFinal_matchId[1]
            )?.status_code
          }
          level={lengthMerge([second_match_homeTeam, second_match_awayTeam])}
          setValue={setSelectedQuarter2}
          currentSelectedTeam={selectedQuarter2}
          details
          data={[second_match_homeTeam, second_match_awayTeam]}
          serverData={serverMatch02}
        />
        <KnockOutMatchBox
          matchId={quarterFinal_matchId[2]}
          finalRealMatchScore={
            quarteFinalsMatchesFromServer?.find(
              (match) => match?.id === quarterFinal_matchId[2]
            )?.matchScore
          }
          matchStatus={
            quarteFinalsMatchesFromServer?.find(
              (match) => match?.id === quarterFinal_matchId[2]
            )?.status_code
          }
          level={lengthMerge([third_match_homeTeam, third_match_awayTeam])}
          setValue={setSelectedQuarter3}
          currentSelectedTeam={selectedQuarter3}
          details
          data={[third_match_homeTeam, third_match_awayTeam]}
          serverData={serverMatch03}
        />
      </div>
    </div>
  );
};
export default QuarterFinalsSection;
