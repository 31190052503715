import React, { useCallback, useContext, useEffect, useState } from "react";
import viLarge from "./images/viLarge.png";
import viLargeActive from "./images/viLargeActive.png";
import GroupStageSingleTeamBox from "./GroupStageSingleTeamBox";
import { TournamentContext } from "../contexts/TournamentContext";

function TeamsBox({ groupLetter, teamsInGroup, groupId, userPredictionGroup }) {
  const { userAddedPredictions } = useContext(TournamentContext);
  const serverGroupRanking = userPredictionGroup?.GroupPredictionRankings;

  const [rankedTeamsArr, setRankedTeamsArr] = useState([]);
  return (
    <div style={{ height: 0 }}>
      <div
        className="container mt-3 group-container d-flex"
        style={{
          position: "relative",
          transform: "translateY(calc(-100% - 1rem))",
        }}
      >
        <div className="teams-box">
          <div className="team-box-header p-2 d-flex border-bottom">
            <img
              className={
                rankedTeamsArr.length === 3 ? "large-vi-active" : "large-vi"
              }
              src={rankedTeamsArr.length === 3 ? viLargeActive : viLarge}
              alt="vi"
            />
            <p className="mb-0 team-box__group-name">{groupLetter}</p>
          </div>
          {teamsInGroup?.map((team) => {
            return (
              <GroupStageSingleTeamBox
                key={team?.id}
                teamFinalRank={team?.ranking}
                teamId={team?.id}
                teamName={team?.name}
                flagImage={team?.flag_img}
                flagFinalImg={team?.flag_final_img}
                rankedTeamsArr={rankedTeamsArr}
                setRankedTeamsArr={setRankedTeamsArr}
                selected={
                  rankedTeamsArr.find((item) => item?.id === team?.id)
                    ? true
                    : false
                }
                groupLetter={groupLetter}
                groupId={groupId}
                userAddedTeamId={serverGroupRanking?.find(
                  (UserAddedTeam) => UserAddedTeam?.teamId === team?.id
                )}
              />
            );
          })}
        </div>
        <div className="next-stage"></div>
      </div>
    </div>
  );
}

export default TeamsBox;
