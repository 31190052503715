import React, { useContext } from "react";
import TeamsBoxKnockout from "./TeamsBoxKnockout";
import viLarge from "../images/viLarge.png";
import TournamentHeadline from "../headers/TournamentHeadline";
import { TournamentContext } from "../../contexts/TournamentContext";

const QuarterFinals = () => {
  const { stageSize,
    selectedQuarter1,
    selectedQuarter2,
    selectedQuarter3,
    selectedQuarter4,
  } = useContext(TournamentContext);

  const completed = [
    selectedQuarter1,
    selectedQuarter2,
    selectedQuarter3,
    selectedQuarter4,
  ].every(s => !!s)
  return (
    <div>
      <TournamentHeadline
        headline={"CUARTOS DE FINAL"}
        paragraph={"Ahora los cuartos. Selecciona el ganador de cada partido."}
        completed={completed}
      />
      <div className="matches-flex-container" style={{ height: stageSize }}>
        <TeamsBoxKnockout QuarterFinals />
      </div>
    </div>
  );
};

export default QuarterFinals;
