import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import ScrollToTop from "./GeneralComponents/ScrollToTop";

import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery";
import "popper.js/dist/popper";
import "bootstrap/dist/js/bootstrap";
import axios from "axios";
import { UserProvider } from "./contexts/UserContext";
import "./fonts/Myriad-Pro-Black-Condensed.otf";


const jwt = localStorage.getItem("jwt-token");
jwt && (axios.defaults.headers.common["jwt-token"] = jwt);

const urlParams = new URLSearchParams(window.location.search);
sessionStorage.setItem("eligibility", urlParams.get("eligibility"));
sessionStorage.setItem("text", urlParams.get("text"));
sessionStorage.setItem("token", urlParams.get("token"));

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop>
        <UserProvider>
          <App />
        </UserProvider>
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
