import TeamsBoxKnockout from "./TeamsBoxKnockout";
import viLarge from "../images/viLarge.png";
import TournamentHeadline from "../headers/TournamentHeadline";
import { useContext } from "react";
import { TournamentContext } from "../../contexts/TournamentContext";

const RoundOfSixteen = ({ setGroups }) => {

  const {
    selectedSixteen1,
    selectedSixteen2,
    selectedSixteen3,
    selectedSixteen4,
    selectedSixteen5,
    selectedSixteen6,
    selectedSixteen7,
    selectedSixteen8,
  } = useContext(TournamentContext)

  const completed = [
    selectedSixteen1,
    selectedSixteen2,
    selectedSixteen3,
    selectedSixteen4,
    selectedSixteen5,
    selectedSixteen6,
    selectedSixteen7,
    selectedSixteen8,
  ].every(s => !!s)

  return (
    <div>
      <div>
        <TournamentHeadline
          headline={"OCTAVOS DE FINAL"}
          paragraph={`¡Muy bien! Ya vas de camino al millón… Ahora selecciona el ganador de cada partido.`}
          completed={completed}
        />
      </div>

      <TeamsBoxKnockout setGroupsSixteen={setGroups} RoundOfSixteen />
    </div>
  );
};

export default RoundOfSixteen;
