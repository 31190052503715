import React, { useContext } from "react";
import { TournamentContext } from "../contexts/TournamentContext";
import TeamsBox from "./TeamsBox";
import TournamentHeadline from "./headers/TournamentHeadline";

function GroupStageContainer() {
  const { groups, stageSize, userAddedPredictions, tournamentPrediction } =
    useContext(TournamentContext);
  const serverGroupPredictions = userAddedPredictions?.groupPredictions;

  const completed =
    tournamentPrediction?.groupPredictions &&
    Object.entries(tournamentPrediction?.groupPredictions)?.every((gp) => {
      return Object.entries(gp[1]).every((t) => {
        return !!t[1];
      });
    });

  return (
    <div
      className="groups-flex-container"
      style={{ height: "calc(" + stageSize + " + 4rem)" }}
    >
      <div style={{ marginBottom: "5rem" }}>
        <TournamentHeadline
          headline={"FASE DE GRUPOS"}
          paragraph={`Por favor, selecciona qué equipos quedarán primero, segundo y tercero al finalizar la Fase de Grupos.
          Piensa bien tus pronósticos porque un millón de euros puede ser tuyo.`}
          editButton={true}
          completed={completed}
          fromGroups
        />
        <div></div>
      </div>

      {groups?.map((group) => {
        return (
          <TeamsBox
            key={group?.id}
            groupId={group?.id}
            groupLetter={group?.displayName}
            teamsInGroup={group?.teams}
            userPredictionGroup={serverGroupPredictions?.find(
              (userAddedGroup) =>
                userAddedGroup?.TournamentGroupId === group?.id
            )}
            GroupStageContainer
          />
        );
      })}
    </div>
  );
}

export default GroupStageContainer;
