import React, { useContext, useEffect } from "react";
import { TournamentContext } from "../contexts/TournamentContext";
import GroupStageContainer from "./GroupStageContainer";
import { Spinner } from "react-bootstrap";
import Final from "./knockOut/Final";
import SemiFinals from "./knockOut/SemiFinals";
import QuarterFinals from "./knockOut/QuarterFinals";
import RoundOfSixteen from "./knockOut/RoundOfSixteen";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { animateScroll as scroll } from "react-scroll";
import $ from "jquery";

const TournamentBody = () => {
  const { loading, setGroupState, setStageSize, setStageIndex, stageSize } =
    useContext(TournamentContext);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    onSwipe: (dir) => {
      // scroll.scrollToTop({ containerId: "scrollableTournamentBody", duration: 300, smooth: "ease-out" })
    },
    beforeChange: (oldIndex, newIndex) => {
      const newSize = [
        "120rem",
        "88rem",
        "50rem",
        "40rem",
        "calc(20rem + 95vw)",
      ][newIndex];
      // $('.TournamentBody').animate({ scrollTop: 0 }, 300);
      // $('.sliderContainer').animate({ height: (parseInt(newSize) + 15) + 'rem' }, 300);
      setStageSize(newSize);
      setStageIndex(newIndex);
    },
  };

  if (!loading) {
    return (
      <div
        style={{
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          height: "100vw",
        }}
      >
        <Spinner animation="border" style={{ color: "rgba(121, 192, 0, 1)" }} />
      </div>
    );
  } else {
    return (
      <div>
        <div className="TournamentBody" id="scrollableTournamentBody">
          <div className="sliderContainer">
            <Slider {...sliderSettings}>
              <div style={{ height: "100%" }} index={1}>
                <GroupStageContainer />
              </div>
              <div style={{ height: "100%" }} index={2}>
                <RoundOfSixteen setGroups={setGroupState} />
              </div>
              <div style={{ height: "100%" }} index={3}>
                <QuarterFinals />
              </div>
              <div style={{ height: "100%" }} index={4}>
                <SemiFinals />
              </div>
              <div style={{ height: "100%" }} index={5}>
                <Final />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
};

export default TournamentBody;
