import React from "react";
import detailsImg from "../images/Details.svg";

const MoreDetails = ({ details }) => {
  return (
    <React.Fragment>
      {/* {details ? (
        <img
          className="detailsImage"
          width="80px"
          height="50px"
          src={detailsImg}
          alt="detailsImg"
        />
      ) : null} */}
    </React.Fragment>
  );
};

export default MoreDetails;
