import React from "react";
import unCheckedVi from "../images/emptyVi.png";

const checkedVi = ({
  home,
  away,
  HomeChecked,
  checkedViState,
  AwayChecked,
}) => {
  let viCheckedOrUnchecked;
  if ((home && HomeChecked) || (away && AwayChecked)) {
    viCheckedOrUnchecked = (
      <img
        style={{ background: "white", borderRadius: 50 }}
        src={checkedViState}
        alt="vi"
      />
    );
  }
  if ((home && !HomeChecked) || (away && !AwayChecked)) {
    viCheckedOrUnchecked = <img src={unCheckedVi} alt="vi" />;
  }

  return (
    <React.Fragment>
      {home && <div className="ml-auto d-flex">{viCheckedOrUnchecked}</div>}
      {away && <div className="ml-auto d-flex">{viCheckedOrUnchecked}</div>}
    </React.Fragment>
  );
};

export default checkedVi;
