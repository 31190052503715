export const matchStatusEnum = {
  notStarted: 0, //The event has not started
  Inplay: 1, //The event is inplay
  HalfTime: 11, //The event is in half-time
  ExtraTime: 12, //The event is in extra time
  Penalties: 13, //The event is in penalties because extra time didn't determinate a winner.
  BreakTime: 14, //Event is in break waiting for extra time or penalties.
  Awarding: 15, //Awarding of a victory to a contestant because there are no other contestants.
  UpdateLater: 2, //Event will be updated later.
  Ended: 3, //Event has ended after 90 minutes.
  AfterPenalties: 31, //Event has ended after penalty shootout.
  AfterExtraTime: 32, //The event has finished after extra time.
  Postponed: 4, //The event has been Postponed.
  Cancelled: 5, //The event has been Cancelled.
  Abandoned: 6, //The event has abandoned and will continue at a later time or day.
  Interrupted: 7, //The event has been interrupted. Can be due to bad weather for example.
  Suspended: 8, //The event has been suspended.
  Awarded: 9, //Winner is beeing decided externally.
  Delayed: 10, //The event is delayed.
  ToBeAnnounced: 17, //The event has not been verified yet.
};

export const hasMatchEndedYet = (matchCode) => {
  return [
    matchStatusEnum.Ended,
    matchStatusEnum.AfterExtraTime,
    matchStatusEnum.AfterPenalties,
  ].includes(matchCode);
};
