import React from "react";

const TeamDetails = ({ flagImage, team, HomeChecked, AwayChecked }) => {
  return (
    <React.Fragment>
      {flagImage ? <img className="small-flag" src={flagImage} alt="" /> : null}
      <span style={HomeChecked ? { color: "white" } : null} className="ml-2">
        {team ? team?.teamName : ""}
      </span>
    </React.Fragment>
  );
};

export default TeamDetails;
