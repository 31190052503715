import React, { useContext, useEffect } from "react";
import { TournamentContext } from "../contexts/TournamentContext";
import successViGroups from "./images/successViGroups.png";
import failViGroups from "./images/failViGroups.png";

const dataSources = {
  user: "user",
  server: "server",
};

const selectedBtnColor = {
  backgroundColor: "#4C658A",
  boxShadow: "inset 0px 3px 4px rgba(0, 0, 0, 0.25)",
  color: "#FFFFFF",
};

const selectedBtnColorDisabled = {
  backgroundColor: "#CFCFCF",
  color: "#FFFFFF",
};

function GroupStageSingleTeamBox({
  teamName,
  flagImage,
  teamId,
  rankedTeamsArr,
  setRankedTeamsArr,
  selected,
  groupLetter,
  groupId,
  userAddedTeamId,
  flagFinalImg,
  teamFinalRank,
}) {
  const {
    group_f,
    setGroupA,
    setGroupB,
    setGroupC,
    setGroupD,
    setGroupE,
    setGroupF,
    postOrPut,
    userEditPressed,
    userAddedPredictions,
    isAfterEndOfGroupStage,
  } = useContext(TournamentContext);

  const rankingFromServer = userAddedTeamId?.ranking;
  // const rankingFromServer = undefined;
  let successOrFailure = undefined;
  if (isAfterEndOfGroupStage) {
    if ((rankingFromServer ?? 4) === teamFinalRank) {
      successOrFailure = successViGroups;
    } else {
      successOrFailure = failViGroups;
    }
  }

  var fullStringGroupName = groupLetter;
  var parts = fullStringGroupName.split(" ");
  parts.shift(); // parts is modified to remove first word
  let onlyLetterOfGroup = parts.join(" ");

  const setGroups = (teams, dataSource = dataSources.user) => {
    let switchKey = groupLetter;
    if (dataSource === dataSources.server) {
      switchKey = onlyLetterOfGroup;
    }

    switch (switchKey) {
      case "Grupo A":
      case "A":
        setGroupA([...teams]);
        break;
      case "Grupo B":
      case "B":
        setGroupB([...teams]);
        break;
      case "Grupo C":
      case "C":
        setGroupC([...teams]);
        break;
      case "Grupo D":
      case "D":
        setGroupD([...teams]);
        break;
      case "Grupo E":
      case "E":
        setGroupE([...teams]);
        break;
      default:
        setGroupF([...teams]);
        break;
    }
  };
  const updateTeamPrediction = (rankToUpdate, dataSource) => {
    switch (dataSource) {
      case dataSources.server:
        const teamWithRank = {
          id: teamId,
          fromGroup: onlyLetterOfGroup,
          groupId: groupId,
          rank: rankToUpdate,
          teamName,
          flagImage,
          teamId,
          flagFinalImg,
        };
        setRankedTeamsArr((prevRankedTeams) => [
          ...prevRankedTeams,
          teamWithRank,
        ]);
        setGroups([...rankedTeamsArr], dataSource);
        break;
      case dataSources.user:
        const isRankAlreadyExists = rankedTeamsArr.find(
          (team) => team?.rank === rankToUpdate && team?.id !== teamId
        );

        const teamIdToUpdate = teamId;
        if (!isRankAlreadyExists) {
          const teamToUpdate = rankedTeamsArr.find(
            (team) => team.id === teamIdToUpdate
          );
          if (teamToUpdate) {
            //DO TO - IN WHAT CASE
            const filter = rankedTeamsArr.filter(
              (item) => item.id !== teamIdToUpdate
            );

            if (rankToUpdate === teamToUpdate?.rank) {
              setRankedTeamsArr([...filter]);
              setGroups([...filter], dataSource);
            } else {
              const newObj = {
                id: teamIdToUpdate,
                fromGroup: onlyLetterOfGroup,
                groupId: groupId,
                rank: rankToUpdate,
                teamName,
                flagImage,
                teamId,
                flagFinalImg,
              };
              setRankedTeamsArr([newObj, ...filter]);
              setGroups([newObj, ...filter]);
            }
          } else {
            if (rankedTeamsArr.length < 3) {
              const teamWithRank = {
                id: teamIdToUpdate,
                fromGroup: onlyLetterOfGroup,
                groupId: groupId,
                rank: rankToUpdate,
                teamName,
                flagImage,
                teamId,
                flagFinalImg,
              };
              setRankedTeamsArr([teamWithRank, ...rankedTeamsArr]);
              setGroups([teamWithRank, ...rankedTeamsArr]);
            }
          }
        }
        break;
      default:
        return;
    }
  };

  const currentRank = rankedTeamsArr.filter((item) => item.id === teamId);

  useEffect(() => {
    setGroups(rankedTeamsArr);
  }, [rankedTeamsArr]);

  useEffect(() => {
    if (rankingFromServer) {
      updateTeamPrediction(rankingFromServer, dataSources?.server);
    }
  }, [rankingFromServer]);

  let displayedBtn;
  if (postOrPut === "put") {
    if (userEditPressed) {
      displayedBtn = selectedBtnColor;
    }

    if (!userEditPressed) {
      displayedBtn = selectedBtnColorDisabled;
    }
  } else {
    displayedBtn = selectedBtnColor;
  }

  const handleClick = (rank, dataSourceClick) => {
    if (postOrPut === "put") {
      if (userEditPressed) {
        updateTeamPrediction(rank, dataSourceClick);
      } else if (!userEditPressed) {
        return null;
      }
    } else {
      updateTeamPrediction(rank, dataSourceClick);
    }
  };

  return (
    <React.Fragment>
      <div className="team-table-row p-2   align-items-center">
        <form className="d-flex align-items-center">
          <img className="small-flag" src={flagImage} alt="flag" />
          <span className="name-team">{teamName}</span>
          <div className="ml-auto d-flex ranking-box ">
            <>
              {/* user did not add predictions yet */}
              <div
                style={
                  selected && currentRank[0]?.rank === 1 ? displayedBtn : null
                }
                className="ranking-btn ranking-btn-1 px-2 py-1"
                onClick={() => handleClick(1, dataSources?.user)}
              >
                1
              </div>
              <div
                style={
                  selected && currentRank[0]?.rank === 2 ? displayedBtn : null
                }
                className="ranking-btn ranking-btn-2 px-2 py-1"
                onClick={() => handleClick(2, dataSources?.user)}
              >
                2
              </div>
              <div
                style={
                  selected && currentRank[0]?.rank === 3 ? displayedBtn : null
                }
                className="ranking-btn ranking-btn-3 px-2 py-1"
                onClick={() => handleClick(3, dataSources?.user)}
              >
                3
                <div
                  style={{
                    position: "absolute",
                    marginLeft: "1.2em",
                    marginTop: "-1.5em",
                  }}
                >
                  <img src={successOrFailure} alt="" />
                </div>
              </div>
            </>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default GroupStageSingleTeamBox;
